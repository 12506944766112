<template>
  <v-app>
  <!-- color="indigo darken-2" -->
  <v-app-bar
    collapse
    collapse-on-scroll
    color="light-green"
    dark
    dense
    >
      <div id="nav">
        <router-link to="/"><i>Beta</i></router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/how">Quick Start</router-link>
      </div>
      <!-- auto space between Home/About and HelloWorld --> 
      <v-spacer></v-spacer>
      <router-view/>
      <!-- auto space between HelloWorld and Log in -->
      <v-spacer></v-spacer>
      <!-- drop down opeions for language support v-menu etc. -->
  </v-app-bar>
  <GmapMap
      :center="center"
      :zoom="12.25"
      map-type-id="roadmap"
      style="width: 100%; height: 700px"
      @click="updateCenter"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"        
      />
      <GmapCircle
      :center="center"
      :radius="5000"
      :options="{fillColor:'green',fillOpacity:0.1}"
      />
    </GmapMap>
    <p>Center: <b>Latitude</b> {{ center.lat.toFixed(2) }} , <b>Longitude</b> {{ center.lng.toFixed(2) }} </p>
    <p></p>

    <div id="msg" style="color:Red;">
      {{ message }}
    </div>

    <p> </p>
    <!-- only when valid data rows > 1 header row -->
    <div id="sum" style="color:Green;" v-if = 'markersdata.length > 1'>
      {{ total }}
    </div>
    <p> </p>
    <div id="appTable" v-if = 'markersdata.length > 1'>
      <GChart
        type="Table"
        :data="markersdata"                    
        :options="tableOptions"
        ref="gChart"
      />
      <p>Source: <a href="http://geothermal.smu.edu/gtda/" target="_blank"><span style='color:green'>SMU Geothermal Laboratory (part of the National Geothermal Data System)</span></a></p>    
    </div>
    <p> </p>
    <!-- only when valid data rows > 1 header row -->
    <div id="xplotChart1" v-if = 'z_temp.length > 3'>
      <GChart
        type="ScatterChart"
        :data="z_temp"
        :options="xplotOption1"
      />    
    </div>
    <div id="xplotChart2" v-if = 'z_temp2.length > 3'>
      <GChart
        type="ScatterChart"
        :data="z_temp2"
        :options="xplotOption2"
      />    
    </div>
  <v-footer
      color="light-green darken-2"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          x-small
          color="white"
          text
          disabled
        >
        </v-btn>
        <v-col
          class="light-green darken-2 text-center white--text"
        >
          <small> Copyright &copy; {{  new Date().getFullYear() }} <strong> Shaleforce LLC</strong>. All rights reserved. </small>
        </v-col>
      </v-row>
    </v-footer>
</v-app>
</template>

<script>

import axios from 'axios';
import { GChart } from "vue-google-charts";

// return true if in range, otherwise false
function inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
}
// determine number of wells per formation
/* var occurrence = function (array) {
    "use strict";
    var result = {};
    if (array instanceof Array) {
        array.forEach(function (v, i) {
            if (!result[v]) {
                result[v] = [i];
            } else {
                result[v].push(i);
            }
        });
        Object.keys(result).forEach(function (v) {
            result[v] = result[v].length;
        });
    }
    return result;
}; */

export default {
  name: 'App',
  components: {
      GChart
    },
  data() {
    return {
      // default to Gulf Coast TX area
      center: { lat: 28.32, lng: -98.13 },
      message: '',
      markers: [],
      markersdata: [],
      z_temp: [],
      z_temp2: [],
      places: [],
      currentPlace: null,
      tableOptions: {
        chart: {
          title: "Well Temperature Summary"
        },
        height: 600,
        width:  900,
        sortColumn: 3
      },
      /* tableEvents: {
        select: () => {          
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();          
          const onSelectionMeaasge = selection.length !== 0 ? 'row was selected' : 'row was diselected'
          alert(onSelectionMeaasge);
        }
      }, */
      xplotOption1: {
        title: 'Simple Proxy Model: Linear fit of Temperature vs Depth (metric unit)',
        hAxis: {title: 'Depth (meter)',
                minValue: 0},
        vAxis: {title: 'Bottom Hole / Max Temperature (°C)',
                maxValue: 220,
                minValue: 0},
        // legend: 'none',
        height: 600,
        width: '90%',
        colors: ['#00A36C'],
        trendlines: { 0: {
          color: '#FFA500',
          lineWidth: 6,
          opacity: 0.3,
          type: 'linear',
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      },
      xplotOption2: {
        title: 'Simple Proxy Model: Linear fit of Temperature vs Depth (imperial unit)',
        orientation: 'vertical',
        hAxis: {title: 'Bottom Hole / Max Temperature (°F)',
                maxValue: 440,
                minValue: 0},
        vAxis: {title: 'Depth (feet)',
                minValue: 0,
                direction: '-1'},
        // legend: 'none',
        height: 600,
        width: '90%',
        colors: ['#00A36C'],
        trendlines: { 0: {
          color: '#FF6347',
          lineWidth: 6,
          opacity: 0.3,
          type: 'linear',
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      }
    }
  },
  // access local data
  /* mounted () {
    axios.get("./data/welldata.json")
    .then(response => (this.welldata = response.data)) 
  }, */
  // Learn more about this component at https://www.npmjs.com/package/vue2-google-maps
  methods: {
    async updateCenter(event) {
      // Remove existing markers
      this.markers = [];
      this.markersdata = [];
      this.z_temp = [];
      this.z_temp2 = [];
      this.message = '';
      this.total = '';

      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      // min latitude set at the southern tip of Florida
      if (!(inRange(this.center.lat, 25.12, 49.38)
        && inRange(this.center.lng, -124.77, -66.95))) {
          this.message = 'Location falls outside the US Lower 48! Please try again.'
        } else { 

        try {
          "use strict";
          const results = await axios({
            method: 'post',
            url: 'https://pmu39zkqxc.execute-api.us-east-1.amazonaws.com/prod/',
            data: {
              lat: this.center.lat,
              lng: this.center.lng
            }
          })
          const markerlabel = [
            // { type: "number", label: "Cluster ID", id: "cid" },
            { type: "string", label: "State", id: "state" },
            { type: "string", label: "County", id: "county" },
            { type: "number", label: "BHT / Max (°C)", id: "eff_temp" },
            { type: "number", label: "Depth (meter)", id: "depth" },
            { type: "number", label: "Thermal K (W/mK)", id: "thermal_k" },
            { type: "number", label: "Heat Flow (mW/m2)", id: "heat_flow" },
            ];
            // console.log(markerdata);
            this.markersdata.push(markerlabel);
          
            // this.markerschart.push(['Content', 'Count', { role: 'style' }]);
            this.z_temp.push(['Depth m', 'Temperature C']);
            this.z_temp2.push(['Depth ft', 'Temperature F']);

          results.data.map((location) => {
            const point = JSON.parse(location.geoJson.S)
            const marker = {
              lat: point.coordinates[1],
              lng: point.coordinates[0],
              state: location.state.S,
              county: location.county.S,
              eff_temp: location.eff_temp.N,
              depth: location.depth.N,
              thermal_k: location.thermal_k.N,
              heat_flow: location.heat_flow.N,
            }
            this.markers.push({ position: marker });

            // console.log(markerdata);
            this.markersdata.push([marker.state, marker.county, parseInt(marker.eff_temp), parseInt(marker.depth),
                parseFloat(marker.thermal_k), parseFloat(marker.heat_flow)]);
            if (marker.eff_temp > 0 & marker.depth > 0) {
              this.z_temp.push([parseFloat(marker.depth), parseFloat(marker.eff_temp)]);
              this.z_temp2.push([parseFloat(marker.depth) * 3.28, (parseFloat(marker.eff_temp) * 9/5) + 32]);
            }

            // label only does not work for histogram
            // this.markersopr.push(marker.operator);

          })
          // if no markers send message
          // console.log(this.flared_sankey.length);
          // console.log(this.z_temp.length);
          if (this.markers.length == 0) {
            this.message = 'No geothermal clusters around this location. Try another spot.'
          } else {
            this.total = 'Way to go. Find a total of ' + this.markers.length +  ' geothermal records.'
          // extract number of occurrence
          // console.log(occurrence(this.markersopr));
          }
        }
        catch (err) {
        // eslint-disable-next-line
        console.err('Error: ', err)
        }
      }
    }
  }  
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
